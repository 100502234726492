import axios from 'axios';
import { useContainerContext } from "../store/ContainerContext";



const baseURL = process.env.GATSBY_STRAPI_SRC;
// Without header call
const instance = axios.create({
  baseURL: baseURL,
});
export const isBrowser = () => typeof window !== "undefined"
export const getUser = () =>
  isBrowser() && window.localStorage.getItem("puser")
    ? JSON.parse(window.localStorage.getItem("puser"))
    : {}

    

    const setUser = (data) => {

      console.log('api => ', data)
      // Store token

      if (isBrowser()) {
        localStorage.setItem('pjwt', data.jwt)
        localStorage.setItem(
          'puser',
          JSON.stringify({
            suname: data.user.username,
            suid: data.user.id,
            logged_user_data: data.user
          })
        )
      }
    }

const removeUser = () => {
  // Store token
  if (isBrowser()) {
    localStorage.removeItem('pjwt');
    localStorage.removeItem('puser');
  }
}

export const handleLogin = ({ username, password }) => {
  console.log('api => ', username, password, baseURL);
  return instance.post("/auth/local", {
    identifier: username,
    password: password,
  }).then((res) => {
    console.log("api => res ", res)
    // Auto confirm user
    if (res.data.user.confirmed) {

      if (res.data.user.role.name === 'Agents'){
        // Login success
        setUser(res.data)
        return {
          user: res.data.user,
          message: res.statusText,
          status: res.status
        }
      } else {
        return {
          message: 'Invalid User Role!',
          status: 400
        }
      }
    }
  })
  .catch((error) => {
    // Login failed
    if (error.response) {
      // Login error
      return {
        //message: error.response.data.message[0].messages[0].message,
        message: "Email-ID or Password is Invalid!",
        status: error.response.status
      }
    } else {
      // Service error
      return {
        message: 'Api service Down!',
        status: 500
      }
    }
  })
  return false
}

export const forgotPassword = ({ username }) => {
  console.log('api => ', username, baseURL);
  return instance.post("/auth/forgot-password", {
    email: username,
  }).then((res) => {
    console.log("api => res ", res)
    // Auto confirm user
    if (res.status === 200) {

      if (res.statusText === 'OK'){
        return {
          user: username,
          message: res.statusText,
          status: res.status
        }
      } else {
        return {
          message: 'Invalid User Role!',
          status: 400
        }
      }
    }
  }).catch((error) => {
    // Login failed
    if (error.response) {
      // Login error
      return {
        //message: error.response.data.message[0].messages[0].message,
        message: "Email-ID is Invalid!",
        status: error.response.status
      }
    } else {
      // Service error
      return {
        message: 'Api service Down!',
        status: 500
      }
    }
  })
  return false
}

export const resetPassword = ({ new_password, reset_pwd_code }) => {
  console.log('api => ', new_password, reset_pwd_code, baseURL);
  return instance.post("/auth/reset-password", {
    code: reset_pwd_code, // code contained in the reset link of step 3.
    password: new_password,
    passwordConfirmation: new_password,
  }).then((res) => {
    console.log("api => res ", res)
    // Auto confirm user
    if (res.status === 200) {

      if (res.statusText === 'OK'){
        return {
          message: res.statusText,
          status: res.status
        }
      } else {
        return {
          message: 'Invalid User Role!',
          status: 400
        }
      }
    }
  }).catch((error) => {
    // Login failed
    if (error.response) {
      // Login error
      return {
        message: error.response.data.message[0].messages[0].message,
        //message: "Email-ID is Invalid!",
        status: error.response.status
      }
    } else {
      // Service error
      return {
        message: 'Api service Down!',
        status: 500
      }
    }
  })
  return false
}

export const isLoggedIn = () => {
  const user = getUser();
  
  
  console.log("api user => ", user);
  
  return !!user.suname
}
export const logout = callback => {
  removeUser()
}
